import styles from "./Testimonial1.module.css";

export default function Testimonial1({}) {
    return (
        <section className={styles.main}>
            <h1 className={styles.title}>AK Industries x Taskoya</h1>
            <div className={styles.content}>
                <img className={styles.logo} alt="Logo de AK Industries" src={require("../../../../Assets/Images/UseCases/ak_logo.jpg")} />
                <p className={styles.question}>Pourquoi étiez-vous à la recherche d’une solution d’organisation comme Taskoya ?</p>
                <p className={styles.answer}>
                    Nous avons beaucoup de petites tâches ponctuelles qu’il est facile d’oublier. Nous cherchions avant tout une solution qui nous
                    permette de centraliser les différentes tâches, d’assurer un suivi régulier et surtout de pouvoir les déléguer si besoin.
                </p>
                <p className={styles.question}>Qu’est-ce qui vous a convaincu dans le logiciel ?</p>
                <p className={styles.answer}>
                    La simplicité d’utilisation. Nous avons testé beaucoup de logiciels par le passé mais la plupart sont trop lourds et demandent
                    beaucoup de temps d’apprentissage. Taskoya a même un certain côté ludique.
                </p>
                <p className={styles.question}>Comment s’est passée l’intégration de Taskoya ?</p>
                <p className={styles.answer}>
                    Dans un premier temps, nous avons décidé de tester le logiciel uniquement avec notre équipe administrative puis nous avons étendu
                    l’utilisation à l’ensemble de nos collaborateurs. Même si plusieurs personnes étaient sceptiques au début, tout le monde l’utilise
                    quotidiennement à présent.
                </p>
                <p className={styles.question}>Comment s’est déroulé l’accompagnement lors de cette intégration ?</p>
                <p className={styles.answer}>
                    Nous avions des points réguliers avec M.Vuillemin, afin de parler des problèmes rencontrés et des éventuelles questions ou
                    propositions sur l’adaptabilité de Taskoya à notre activité. De plus, chaque collaborateur a pu bénéficier d’une formation d’au
                    moins une heure afin de se familiariser avec le logiciel.
                </p>
                <p className={styles.question}>Comment s’est passée la prise en main de Taskoya par vos équipes ?</p>
                <p className={styles.answer}>
                    La prise en main a été très rapide. Nous avons effectué une semaine de test en équipe réduite, puis en deux semaines tout le monde
                    pouvait utiliser aisément Taskoya. Au début, il faut prendre l’habitude d’aller sur le logiciel. Maintenant, c’est devenu un
                    automatisme.
                </p>
                <p className={styles.question}>Comment se traduit l’impact de Taskoya sur l’activité d’AK Industrie ?</p>
                <p className={styles.answer}>
                    L’utilisation de Taskoya nous a permis de mieux structurer notre flux d’information interne. Nous avons éliminé la plupart des
                    problèmes de communication comme « On ne me l’a pas dit » ; « Je ne suis pas au courant » ; « C’était à moi de le faire ? » ; etc.
                </p>
            </div>
        </section>
    );
}
