import { useNavigate } from "react-router-dom";
import styles from "./MainNavigationButton.module.css";

export default function MainNavigationButton({ title, icon, description, link, external }) {
    const navigate = useNavigate();

    return (
        <div onClick={!external ? () => navigate(link) : () => (window.location.href = link)} className={styles.button}>
            <img className={styles.buttonImage} src={icon} alt={description} />
            <div className={styles.buttonMainContent}>
                <div className={styles.buttonTitle}>{title}</div>
                <div className={styles.buttonDescription}>{description}</div>
            </div>
            {!link && <div className={styles.unavailable}>Bientôt disponible !</div>}
        </div>
    );
}
