import showTaskProgression from "../../Assets/Videos/News/Afficher la progression d une action.webm";
import addFile from "../../Assets/Videos/News/Ajouter une pièce jointe à une action.webm";
import taskInMail from "../../Assets/Videos/News/La fonctionnalité Taskinmail.webm";
import notifyBeforeDeadline from "../../Assets/Videos/News/Notifiez les exécutants de l_action avant sa date de fin.webm";
import timerAdded from "../../Assets/Videos/News/Un minuteur a été ajouté aux actions.webm";
import recurrencesImprovements from "../../Assets/Videos/News/Amélioration des récurrences.webm";

import styles from "./News.module.css";

function NewsItem({ title, video, children }) {
    return (
        <>
            <p className={styles.title}>{title}</p>
            <ul className={styles.inset}>{children}</ul>
            <video className={styles.video} autoPlay loop muted alt="Vidéo de démonstration" src={video} />
        </>
    );
}

function NewsBulletpoint({ children }) {
    return <li className={styles.bulletPoint}>{children}</li>;
}

export default function News() {
    return (
        <section className={styles.content}>
            <NewsItem title={"Les récurrences ont été améliorées !"} video={recurrencesImprovements}>
                <NewsBulletpoint>Vous pouvez maintenant gérer encore plus précisément quand vous voulez que votre action se répète.</NewsBulletpoint>
                <NewsBulletpoint>Une prévisualisation des 30 prochaines répétitions de votre action est maintenant affichée et vous permet d'avoir une visibilité
                    sur le long terme.</NewsBulletpoint>
                <NewsBulletpoint>Toute l'interface de création de récurrences a été revue pour rendre l'ajout de récurrences beaucoup plus clair et intuitif.</NewsBulletpoint>
            </NewsItem>
            <NewsItem title={"Afficher la progression d’une action"} video={showTaskProgression}>
                <NewsBulletpoint>Vous pouvez gérer la progression d’une action depuis le listing, ou en ouvrant la tâche en question.</NewsBulletpoint>
                <NewsBulletpoint>Vous pouvez maintenant cliquer sur le pourcentage d'une action en cours, indiqué normalement dans son bouton de statut "En cours",
                    pour augmenter la progression de cette action de 10%.</NewsBulletpoint>
                <NewsBulletpoint>De la même manière, vous pouvez effectuer un Shift+Clic sur le pourcentage pour réduire la progression de l'action de 10%.</NewsBulletpoint>
            </NewsItem>
            <NewsItem title={"Un minuteur a été ajouté aux actions !"} video={timerAdded}>
                <NewsBulletpoint>Depuis l'onglet Caractéristiques d'une action, vous pouvez maintenant démarrer un minuteur de la durée que vous choisissez. Le
                    minuteur apparaîtra en haut à droite du panel de l'action, peu importe sur quel onglet vous vous trouvez.</NewsBulletpoint>
                <NewsBulletpoint>Un popup apparaîtra pour vous prévenir lorsque le temps sera écoulé.</NewsBulletpoint>
                <NewsBulletpoint>Le minuteur s'arrête automatiquement quand le panel de l'action est fermé.</NewsBulletpoint>
            </NewsItem>
            <NewsItem title={"Ajoutez des pièces jointes à vos actions via votre Drive"} video={addFile}>
                <NewsBulletpoint>Dans la page "Paramètres du compte", un nouveau bouton "Gérer mes drives" a été ajouté. Cette nouvelle page vous permet de lier
                    des comptes Google, Dropbox ou Microsoft directement à vos organisations.</NewsBulletpoint>
                <NewsBulletpoint>Un nouveau bouton "Ajouter un fichier" est désormais présent dans vos actions. Cliquez dessus pour sélectionner des pièces jointes
                    à ajouter à l'action depuis votre drive.</NewsBulletpoint>
            </NewsItem>
            <NewsItem title={"La fonctionnalité Taskinmail"} video={taskInMail}>
                <NewsBulletpoint>Créez une tâche depuis un e-mail reçu. Il vous suffit de transférer l'e-mail reçu à l’adresse taskinmail@taskoya.com. La tâche
                    sera alors automatiquement créée dans le dossier boîte d’entrée. Avec comme nom l’objet du mail, et en description le message
                    qu’il contient.</NewsBulletpoint>
                <NewsBulletpoint>Vous pouvez ajouter votre adresse E-Mail dans les paramètres de vos organisations. Vous pouvez ainsi par exemple lier votre
                    adresse email personnelle à votre organisation privée. Et de même pour votre organisation professionnelle.</NewsBulletpoint>
            </NewsItem>
            <NewsItem title={"Notifiez les exécutants de l’action avant sa date de fin"} video={notifyBeforeDeadline}>
                <NewsBulletpoint>En tant que superviseur ou responsable d'une action, il est possible, dans l'onglet Délégation d'une action, de notifier les
                    exécutants de l'action avant sa date de fin.</NewsBulletpoint>
                <NewsBulletpoint>Les exécutants de l'action recevront alors automatiquement une notification une fois ce délai dépassé.</NewsBulletpoint>
                <NewsBulletpoint>L'option se notifier avant la deadline se situe dans l'onglet Caractéristiques.</NewsBulletpoint>
            </NewsItem>
        </section>
    );
}
