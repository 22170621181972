import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import UseCaseLayout from "./Layouts/UseCaseLayout/UseCaseLayout";
import Home from "./Pages/Home/Home";
import News from "./Pages/News/News";
import AdvancedFeatures from "./Pages/Sections/AdvancedFeatures/AdvancedFeatures";
import Divers from "./Pages/Sections/AdvancedFeatures/Divers/Divers";
import FAQ from "./Pages/Sections/FAQ/FAQ";
import AccountManagement from "./Pages/Sections/StartingTaskoya/AccountManagement/AccountManagement";
import BaseFunctionalities from "./Pages/Sections/StartingTaskoya/BaseFunctionalities/BaseFunctionalities";
import FirstSteps from "./Pages/Sections/StartingTaskoya/FirstSteps/FirstSteps";
import StartingTaskoya from "./Pages/Sections/StartingTaskoya/StartingTaskoya";
import Testimonial1 from "./Pages/UseCases/Testimonials/Testimonial1/Testimonial1";
import UseCase1 from "./Pages/UseCases/UseCases/UseCase1";
import UseCasesHome from "./Pages/UseCases/UseCases/UseCasesHome/UseCasesHome";
import Webinar1 from "./Pages/UseCases/Webinars/Webinar1";

function App() {
    return (
        <div>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="startingTaskoya" element={<StartingTaskoya />}>
                        <Route path="firstSteps" element={<FirstSteps />} />
                        <Route path="baseFunctionalities" element={<BaseFunctionalities />} />
                        <Route path="accountManagement" element={<AccountManagement />} />
                    </Route>
                    <Route path="advancedFeatures" element={<AdvancedFeatures />}>
                        <Route path="miscellaneous" element={<Divers />} />
                    </Route>
                    <Route path="useCases" element={<UseCaseLayout title={"CAS D'USAGE"} />}>
                        <Route path="1" element={<UseCase1 />} />
                        <Route index element={<UseCasesHome />} />
                    </Route>
                    <Route path="webinars" element={<UseCaseLayout title={"WEBINAIRES"} />}>
                        <Route path="1" element={<Webinar1 />} />
                    </Route>
                    <Route path="testimonials" element={<UseCaseLayout title={"TÉMOIGNAGES"} />}>
                        <Route path="1" element={<Testimonial1 />} />
                    </Route>
                    <Route path="news" element={<UseCaseLayout title={"NOUVEAUTÉS"} />}>
                        <Route index element={<News />} />
                    </Route>
                    <Route path="faq" element={<FAQ />} />
                </Routes>
            </BrowserRouter>
        </div>
    );
}

export default App;
