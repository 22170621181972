import createCustomFilter from "../../../../Assets/Videos/AdvancedFeatures/Créer un filtre personnalisé en fonction de ses besoins.webm";
import duplicateToUsers from "../../../../Assets/Videos/AdvancedFeatures/Dupliquer une tâche à plusieurs éxécutants.webm";
import timestampDescription from "../../../../Assets/Videos/AdvancedFeatures/Horodater un commentaire dans la description d'une action.webm";
import multipickTasks from "../../../../Assets/Videos/AdvancedFeatures/Multipicking de tâches.webm";
import generateTemplateFolder from "../../../../Assets/Videos/AdvancedFeatures/Générer un dossier modèle.webm";
import taskInMail from "../../../../Assets/Videos/AdvancedFeatures/Taskinmail pro.webm";


import Article from "../../../../Components/Article/Article";
import Caption from "../../../../Components/Caption/Caption";

export default function Divers() {
    return (
        <>
            <Article anchor={"createFilter"} title={"Créer un filtre personnalisé en fonction de ses besoins"} illustration={createCustomFilter}>
                <div>Vous utilisez régulièrement le même filtre ? Enregistrez-le afin de l’utiliser en un clic !</div>
            </Article>
            <Article anchor={"timestampDescription"} title={"Horodater une note, dans une tâche"} illustration={timestampDescription}>
                <div>Pour noter automatiquement l’auteur, et l’heure de votre message, il vous suffit d’un clic droit ! </div>
            </Article>
            <Article anchor={"multipickTasks"} title={"Multipicking de tâches"} illustration={multipickTasks}>
                <div>Sélectionnez plusieurs tâches en même temps afin de modifier leur date de fin </div>
            </Article>
            <Article anchor={"taskInMail"} title={"Création d’une tâche à partir d’un E-Mail reçu"} illustration={taskInMail}>
                <div>
                    Lorsque vous recevez un mail que vous souhaitez transformer en action dans Taskoya; envoyez un email à taskinmail@taskoya.com. Une
                    tâche sera automatiquement créée dans votre dossier "Boîte d’entrée”.
                <Caption title={"Lier une organisation à son email"}>
                    <div>
                        Pour choisir dans quelle boîte d'entrée apparaîtra l'action, liez votre adresse email à une organisation dans les paramètres
                        de l'organisation.
                    </div>
                </Caption>
                </div>
            </Article>
            <Article anchor={"duplicateToUsers"} title={"Dupliquer une tâche à plusieurs exécutants"} illustration={duplicateToUsers}>
                <div>Vous souhaitez dupliquer la même tâche à plusieurs exécutants ? Cliquez sur le bouton </div>
            </Article>
            <Article anchor={"followTask"} title={"Suivre une action"} illustration={createCustomFilter}>
                <div>Vous souhaitez suivre une action et ses changements de statut ? </div>
            </Article>
            <Article anchor={"createTemplateFolder"} title={"Créer un dossier modèle "} illustration={generateTemplateFolder}>
                <div>
                    Vous savez que vous allez être amené à utiliser un dossier et ses tâches plusieurs fois ? Créez un dossier modèle afin de le
                    redéployer en deux clics !
                </div>
                <br />
                <i>Exemple : Vous recrutez un nouveau collaborateur et souhaitez planifier les tâches administratives qui en découlent. </i>
            </Article>
        </>
    );
}
