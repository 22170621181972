import styles from './Caption.module.css';


export default function Caption({title, children})
{
    return (
        <div className={styles.main}>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}> {children}</div>
        </div>
    );
}