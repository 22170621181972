import { useState } from "react";
import styles from './FAQItem.module.css';


export default function FAQItem({question, answer})
{
    const [open, setOpen] = useState(false);

    return (
        <div className={styles.main}>
            <div onClick={() => setOpen(!open)} className={styles.question}>{question}</div>
            <div style={{maxHeight:open?500:0}} className={styles.answer}>{answer}</div>
        </div>
    );
}