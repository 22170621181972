import FAQItem from '../../../Components/FAQItem/FAQItem';
import styles from './FAQ.module.css';


export default function FAQ()
{
    return (
        <div>
            <h1 className={styles.title}>FOIRE AUX QUESTIONS</h1>
            <div className={styles.body}>
                <FAQItem question={"Quelle configuration matérielle est requise pour utiliser Taskoya ?"} answer={"Il n’y a pas de configuration spécifique requise. Une connexion internet suffit, par contre le navigateur chrome est conseillé pour garantir la meilleure compatibilité."}/>
                <FAQItem question={"Ai-je besoin d’une connexion internet pour accéder à Taskoya ?"} answer={"Sur un ordinateur vous avez besoin d’une connexion internet pour accéder à Taskoya. Sur les applications mobiles il est bien sûr nécessaire de se connecter pour actualiser vos actions, mais un mode hors connexion vous permet de travailler sans internet. Vos modifications seront prises en compte à la reconnexion au réseau."}/>
                <FAQItem question={"Comment faire pour avoir Taskoya sur mon Téléphone ?"} answer={"Taskoya dispose de deux versions iOs et Android, disponibles sur les stores. Il suffit de les télécharger et de vous connecter avec vos identifiants et mot de passe."}/>
                <FAQItem question={"L’application mobile peut-elle remplacer la version web ?"} answer={"Non. L’application mobile est une version simplifiée de l’application web qui vous permet par exemple : de consulter vos actions, valider vos tâches, saisir de nouvelles actions… C’est un complément de l’application web lors votre mobilité pour avoir toujours son organisation à portée de main."}/>
                <FAQItem question={"Comment puis-je rajouter une organisation ?"} answer={"Pour ajouter une organisation, merci de contacter notre service client à l’adresse suivante : contact@taskoya.com"}/>
                <FAQItem question={"Je dois rajouter des collaborateurs à Taskoya, que dois-je faire ?"} answer={"La première question à vous poser est-ce que le nombre de licences disponibles dans mon organisation est suffisant. Si c’est le cas, vous pouvez créer un nouvel utilisateur depuis l’espace administrateur de Taskoya. Si vous ne disposez pas de suffisamment de licences, merci de contacter notre service client : contact@taskoya.com"}/>
                <FAQItem question={"J’ai besoin d’un accès Taskoya pour un stagiaire est-ce possible ?"} answer={"Oui c’est possible. Contactez-nous à contact@taskoya.com en nous indiquant le nombre de licences nécessaires et la durée nécessaire. La licence sera alors rajoutée à votre facturation uniquement pendant la durée du stage."}/>
                <FAQItem question={"Comment rattacher des documents à des actions dans Taskoya ?"} answer={"Pour rattacher des documents à des actions, nous vous invitons à consulter notre rubrique aide : Débuter sur Taskoya"}/>
            </div>
        </div>
    );
}