import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styles from "./AdvancedFeatures.module.css";

export default function AdvancedFeatures() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                document.querySelector(location.hash)?.scrollIntoView({ behavior: "smooth" });
            }, 400);
        }
    }, [location.hash]);

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <img alt="Le logo de Taskoya" onClick={() => navigate("/")} src={require("../../../Assets/Images/taskoya256x150.png")} />
            </div>
            <h1 className={styles.title}>FONCTIONNALITÉS AVANCÉES</h1>
            <div className={styles.body}>
                <div className={styles.navbar}>
                    <>
                        <HashLink className={styles.articleLink} smooth to={"/advancedFeatures/miscellaneous#createFilter"}>
                            Créer un filtre personnalisé en fonction de ses besoins
                        </HashLink>
                        <HashLink className={styles.articleLink} smooth to={"/advancedFeatures/miscellaneous#timestampDescription"}>
                            Horodater une note, dans une tâche
                        </HashLink>
                        <HashLink className={styles.articleLink} smooth to={"/advancedFeatures/miscellaneous#multipickTasks"}>
                            Multipicking de tâches
                        </HashLink>
                        <HashLink className={styles.articleLink} smooth to={"/advancedFeatures/miscellaneous#taskInMail"}>
                            Création d’une tâche à partir d’un E-Mail reçu
                        </HashLink>
                        <HashLink className={styles.articleLink} smooth to={"/advancedFeatures/miscellaneous#duplicateToUsers"}>
                            Dupliquer une tâche à plusieurs exécutants
                        </HashLink>
                        <HashLink className={styles.articleLink} smooth to={"/advancedFeatures/miscellaneous#followTask"}>
                            Suivre une action
                        </HashLink>
                        <HashLink className={styles.articleLink} smooth to={"/advancedFeatures/miscellaneous#createTemplateFolder"}>
                            Créer un dossier modèle
                        </HashLink>
                    </>
                </div>
                <div className={styles.content}>
                    <Outlet />
                </div>
                <div className={styles.rightBar}></div>
            </div>
        </div>
    );
}
