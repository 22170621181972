export default function EmbeddedYoutubeVideo({ link }) {
    return (
        <>
            <div style={{ position: "relative", paddingBottom: "56.25%", height: 0, overflow: "hidden", maxWidth: "100%" }}>
                <iframe
                    title="Video"
                    style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
                    src={link}
                    frameborder="0"
                    allowfullscreen
                ></iframe>
            </div>
        </>
    );
}
