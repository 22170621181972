import { Outlet, useNavigate } from "react-router-dom";
import styles from "./UseCaseLayout.module.css";

export default function UseCaseLayout({ title }) {
    const navigate = useNavigate();
    return (
        <div>
            <div className={styles.header}>
                <img alt="Le logo de Taskoya" onClick={() => navigate("/")} src={require("../../Assets/Images/taskoya256x150.png")} />
            </div>
            <h1 className={styles.title}>{title.toUpperCase()}</h1>
            <div className={styles.body}>
                <Outlet />
            </div>
        </div>
    );
}
