
import Article from "../../../../Components/Article/Article";
import Caption from "../../../../Components/Caption/Caption";
import Category from "../../../../Components/Category/Category";
import NumberedList from "../../../../Components/NumberedList/NumberedList";
import Subcategory from "../../../../Components/Subcategory/Subcategory";

//Vidéos pour la catégorie "Les premiers pas"
import createFolder from "../../../../Assets/Videos/StartingTaskoya/1-Ou_creer_un_dossier.webm";
import manageRights from "../../../../Assets/Videos/StartingTaskoya/Gérer les droits des autres utilisateurs.webm";
import createTags from "../../../../Assets/Videos/StartingTaskoya/Créer des tags.webm";
import createTask from "../../../../Assets/Videos/StartingTaskoya/Créer une action.webm";
import fillTask from "../../../../Assets/Videos/StartingTaskoya/Remplir la section action.webm";
import repeatTask from "../../../../Assets/Videos/StartingTaskoya/Répetez votre action régulièrement.webm";
import notifyBeforeDeadline from "../../../../Assets/Videos/StartingTaskoya/Être notifié à l’approche de l’échéance.webm";

export default function FirstSteps()
{
    return (
        <Category title={"Les premiers pas"}>
        <Subcategory anchor={"createFolder"} title={"Créer un dossier"}>
            Le dossier vous permet d’organiser et de classifier vos actions. Vous pouvez ainsi adapter la structure de votre Taskoya en fonction de votre organisation. Un dossier peut donc être un projet, un ensemble de process ou un service, par exemple : RH ou Administratif et financier. Commencez par un dossier simple pour vous entraîner. 
            <Caption title={"Les rôles chez Taskoya"}>
                <p><b>Responsable :</b> propriétaire de la tâche ou du projet, il peut l'administrer à loisir</p>
                <p><b>Superviseur :</b> observateur de la tâche ou du projet, il peut la consulter et modifier certains détails</p>
                <p><b>Exécutant :</b> délégataire de la tâche, il peut simplement valider son exécution ou non</p>
            </Caption>
            <Article anchor={"whereToCreateFolder"}  title={"Où créer un dossier"} illustration={createFolder}/>
            <Article anchor={"addSupervisorsToFolder"} title={"Ajouter des superviseurs au dossier"} illustration={manageRights}>
                <div>
                    Un dossier est visible par l’utilisateur, s’il a au moins une action qui lui est assignée dans ce dossier, ou s’il est ajouté comme superviseur au dossier. 
                    <br/>Les dossiers de votre partie privée vous sont réservés et accessibles par personne d’autre. 
                    <NumberedList>
                        <NumberedList.Element number={1}>Sélectionnez un responsable qui sera le seul à pouvoir modifier les paramètres du dossier. Par défaut, le responsable du dossier sera son créateur. </NumberedList.Element>
                        <NumberedList.Element number={2}>Renseignez les superviseurs qui pourront créer des actions dans votre dossier. </NumberedList.Element>
                    </NumberedList>
                </div>
            </Article>
            <Article anchor={"createTags"} title={"Créer des tags"} illustration={createTags}>
                <div>
                    Dans Taskoya, la notion de Tags s’apparente à des sous dossiers, ou à des étiquettes qui peuvent être comunes à plusieurs dossiers. Ce qui va permettre d’effectuer une recherche avancée à l’aide de filtres croisés.  
                    <NumberedList>
                        <NumberedList.Element number={1}>Choisissez le nom du tag </NumberedList.Element>
                        <NumberedList.Element number={2}>Cliquez sur “Ajouter” </NumberedList.Element>
                    </NumberedList>
                </div>
            </Article>
        </Subcategory>
        <Subcategory anchor={"createTask"} title={"Créer une action"}>
            Une action doit toujours commencer par un verbe. Diviser vos objectifs en différentes tâches afin de pouvoir les atteindre plus facilement. Dans la mesure du possible, éviter le verbe faire, mais choisir un verbe précis, qui incite à l’action. 
            <Article anchor={"whereToCreateTask"} title={"Où créer une action"} illustration={createTask}/>
            <Article anchor={"fillTaskSection"} title={"Remplir la section action"} illustration={fillTask}>
                <NumberedList>
                    <NumberedList.Element number={1}>Précisez une date et heure d’échéance</NumberedList.Element>
                    <NumberedList.Element number={2}>Sélectionnez un ou plusieurs tags si vous le souhaitez </NumberedList.Element>
                    <NumberedList.Element number={3}>Renseignez la durée estimée de l’action, si vous le souhaitez </NumberedList.Element>
                    <NumberedList.Element number={4}>Rattachez éventuellement une pièce jointe depuis votre Drive ou un lien utile à la réalisation de l’action </NumberedList.Element>
                    <NumberedList.Element number={5}>Laissez un commentaire dans la partie description de l’action. Pour horodater votre commentaire, cliquez droit et sélectionnez “horodater”. Taskoya insérera votre nom d’utilisateur ainsi que la date et l’heure du commentaire automatiquement. </NumberedList.Element>
                </NumberedList>
            </Article>
            <Article anchor={"setupDelegationSection"} title={"Paramétrer la section délégation d’une action collaborative"} illustration={null}>
                <NumberedList>
                    <NumberedList.Element number={1}>Choisissez une ou plusieurs personnes à qui attribuer votre action </NumberedList.Element>
                    <NumberedList.Element number={2}>Définissez les superviseurs qui auront accès à votre action </NumberedList.Element>
                </NumberedList>
            </Article>
            <Article anchor={"setupRecurrence"} title={"Répétez votre action régulièrement en planifiant une récurrence"} illustration={repeatTask}>
                Si vous devez effectuer une action régulièrement vous pouvez créer une récurrence. L’action peut être répétée une ou plusieurs fois par heure, jour, semaine, mois, année avec une précision très fine. 
            </Article>
            <Article anchor={"notifyBeforeDeadline"} title={"Être notifié à l’approche de l’échéance"} illustration={notifyBeforeDeadline}>
                <div>
                    L'exécutant de l’action peut recevoir un rappel avant l’échéance de l’action.
                    <div style={{display:"flex", alignItems:"center"}}>
                        <div>Ce rappel se présentera sous la forme d’une petite notification :</div>
                        <img alt="Une bulle rouge contenant le chiffre 1 à côté du bouton notification sur Taskoya." src={require("../../../../Assets/Images/notificationIcon.png")}/>
                    </div>
                </div>
            </Article>
        </Subcategory>
    </Category>
    );
}