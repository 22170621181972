import styles from './BaseFunctionalities.module.css';


import Article from "../../../../Components/Article/Article";
import Caption from "../../../../Components/Caption/Caption";
import Category from "../../../../Components/Category/Category";
import NumberedList from "../../../../Components/NumberedList/NumberedList";
import Subcategory from "../../../../Components/Subcategory/Subcategory";

import changeTaskStatus from "../../../../Assets/Videos/StartingTaskoya/Changer le statut de son action.webm";
import changeTaskStatus2 from "../../../../Assets/Videos/StartingTaskoya/Changer le statut de son action-2.webm";
import postponeTask from "../../../../Assets/Videos/StartingTaskoya/Repousser une action.webm";
import duplicateTask from "../../../../Assets/Videos/StartingTaskoya/Dupliquer une action.webm";
import editTaskUser from "../../../../Assets/Videos/StartingTaskoya/Modifier l_éxécutant d_une action.webm";
import addFile from "../../../../Assets/Videos/StartingTaskoya/Ajouter une pièce jointe à une action.webm";
import searchByTaskName from "../../../../Assets/Videos/StartingTaskoya/Rechercher par nom d_action.webm";
import searchByTaskFolder from "../../../../Assets/Videos/StartingTaskoya/Rechercher par dossier.webm";
import searchByTaskTag from "../../../../Assets/Videos/StartingTaskoya/Rechercher par tag.webm";
import searchByTaskUser from "../../../../Assets/Videos/StartingTaskoya/Rechercher par éxécutant.webm";
import editDisplaySettings from "../../../../Assets/Videos/StartingTaskoya/Modifier l_affichage.webm";
import manageViews from "../../../../Assets/Videos/StartingTaskoya/Gérer les vues.webm";
import editFolder from "../../../../Assets/Videos/StartingTaskoya/Modifier un dossier.webm";
import duplicateFolder from "../../../../Assets/Videos/StartingTaskoya/Dupliquer un dossier.webm";




export default function BaseFunctionalities() {
    return (
        <Category title={"Les fonctionnalités de base"}>
            <Subcategory anchor={"manageTasks"} title={"Gérer ses actions"}>
                <Caption title={"Les rôles chez Taskoya"}>
                    <p><b>Responsable :</b> propriétaire de la tâche ou du projet, il peut l'administrer à loisir</p>
                    <p><b>Superviseur :</b> observateur de la tâche ou du projet, il peut la consulter et modifier certains détails</p>
                    <p><b>Exécutant :</b> délégataire de la tâche, il peut simplement valider son exécution ou non</p>
                </Caption>
                <Article anchor={"changeTaskStatus"} title={"Changer le statut de son action"}>
                    <div>
                        Une fois créée, l’action se retrouve votre listing avec le statut “en attente”. Vous pouvez directement mettre à jour son statut.
                        <NumberedList>
                            <NumberedList.Element number={1}>
                                <div className={styles.numberedListInnerDiv}>
                                    <div>Sélectionnez l’icône</div> <img alt='contenant un crayon' className={styles.numberedListImage} src={require("../../../../Assets/Images/statusInProgressIcon.png")} /> <div>pour signifier qu’elle est en cours de réalisation </div>
                                </div>
                            </NumberedList.Element>
                            <NumberedList.Element number={2}>
                                <div className={styles.numberedListInnerDiv}>
                                    <div>Gérez l’avancement avec la barre de progression</div>
                                    <img alt=", représentée par un curseur bleu coulissant sur une ligne" className={styles.numberedListImage} src={require("../../../../Assets/Images/progressBar.png")} />
                                </div>
                            </NumberedList.Element>
                            <NumberedList.Element number={3}>
                                <div className={styles.numberedListInnerDiv}>
                                    <div>Validez ensuite l’action grâce à l’icône</div>
                                    <img alt="contenant un V" className={styles.numberedListImage} src={require("../../../../Assets/Images/statusCompletedIcon.png")} />
                                </div>
                            </NumberedList.Element>
                        </NumberedList>
                        <Article illustration={changeTaskStatus} />
                        <Article illustration={changeTaskStatus2}>
                            <div className={styles.numberedListInnerDiv}>
                                <div>Vous pouvez archiver</div> <img alt="en cliquant sur l'icône contenant une boite avec une flèche vers le bas" className={styles.numberedListImage} src={require("../../../../Assets/Images/statusArchivedIcon.png")} /> <div>une action que vous ne souhaitez plus voir apparaître.</div>
                            </div>
                        </Article>
                    </div>
                </Article>
                <Article anchor={"postponeTask"} title={"Repousser une action"} illustration={postponeTask}>
                    <div>
                        <i>Vous êtes débordé et devez remettre une action au lendemain ? Pas de panique ! </i>
                        <div>
                            Les actions en retard s'affichent en rouge et les actions du jour en orange.
                            <br />Attention, seuls les utilisateurs superviseurs de l’action peuvent la modifier.
                        </div>
                    </div>
                </Article>
                <Article anchor={"duplicateTask"} title={"Dupliquer une action"} illustration={duplicateTask}>
                    <i>Vous pouvez aussi dupliquer votre action très rapidement ! </i>
                </Article>
                <Article anchor={"editTaskUser"} title={"Modifier l'exécutant d’une action"} illustration={editTaskUser}>
                    <div>
                        <i>Vous accueillez un nouveau collaborateur et souhaitez lui déléguer certaines actions ?  </i>
                        <div>
                            Dans l’onglet délégation, vous pouvez changer l'exécutant de l’action .
                        </div>
                    </div>
                </Article>
                <Article anchor={"addFile"} title={"Ajouter une pièce jointe"} illustration={addFile}>
                    Liez votre compte Drive à une organisation dans votre Taskoya.
                    Cela vous permettra d’ajouter des pièces jointes à vos tâches !
                    Rendez-vous dans la partie “paramètres du compte”, puis “Gérer mes drives”
                    Vous pourrez ensuite ajouter le lien d’un fichier de votre drive à une tâche
                </Article>
            </Subcategory>
            <Subcategory anchor={"masterFilters"} title={"Maîtriser les filtres"}>
                Nous vous proposons une recherche détaillée afin de filtrer les actions de votre listing.
                N’hésitez pas à croiser le filtrage de plusieurs colonnes pour une vue encore plus détaillée !
                <Article anchor={"filterByName"} title={"Rechercher par nom d’action"} illustration={searchByTaskName}>
                    <i>Tapez simplement les premières lettres du nom de l’action ! </i>
                </Article>
                <Article anchor={"filterByFolder"} title={"Rechercher par dossier"} illustration={searchByTaskFolder}>
                    <div>
                        <i>Pour avoir une vue d’ensemble des actions d’un dossier, utilisez un filtre ! </i>
                        <div>
                            Pour ce faire, il vous suffit de sélectionner le dossier dans la liste de votre organisation.

                            Puis d’enlever votre nom dans la recherche par exécutant
                        </div>
                    </div>
                </Article>
                <Article anchor={"filterByTag"} title={"Rechercher par tag"} illustration={searchByTaskTag}>
                    Besoin d’avoir une vision claire sur un sujet très rapidement ?
                </Article>
                <Article anchor={"filterByUser"} title={"Rechercher par exécutant"} illustration={searchByTaskUser}>
                    <i>Le matin je peux voir très vite si mes collaborateurs sont débordés ! </i>
                </Article>
            </Subcategory>
            <Subcategory anchor={"organizeDisplay"} title={"Organiser l'affichage"}>
                <Article anchor={"modifyDisplay"} title={"Modifier l’affichage"} illustration={editDisplaySettings}>
                    <div className={styles.numberedListInnerDiv}>
                        <div>Personnalisez le listing de vos actions dans l’onglet</div>
                        <img alt="paramètres d'affichage" className={styles.numberedListImage} src={require("../../../../Assets/Images/displaySettingsIcon.png")} />
                    </div>
                </Article>
                <Article anchor={"manageViews"} title={"Gérer les vues"} illustration={manageViews}>
                    <i>Masquez certaines organisations ou dossiers pour une vue plus épurée ! </i>
                </Article>
            </Subcategory>
            <Subcategory anchor={"manageFolders"} title={"Gérer ses dossiers"}>
                <Article anchor={"editFolder"} title={"Modifier un dossier"} illustration={editFolder}>
                    <NumberedList>
                        <NumberedList.Element number={1}>
                            <div className={styles.numberedListInnerDiv}>
                                <div>Sélectionnez l’icône</div> <img alt='contenant un rouage' className={styles.numberedListImage} src={require("../../../../Assets/Images/settingsIcon.png")} /> <div>à droite du dossier dont vous êtes le responsable</div>
                            </div>
                        </NumberedList.Element>
                        <NumberedList.Element number={2}>
                            Changez le nom, le responsable, les superviseurs, ajoutez un tag ou une description 
                        </NumberedList.Element>
                    </NumberedList>
                </Article>
                <Article anchor={"duplicateFolder"} title={"Dupliquer un dossier"} illustration={duplicateFolder}>
                    <NumberedList>
                        <NumberedList.Element number={1}>
                            <div className={styles.numberedListInnerDiv}>
                                <div>Sélectionnez l’icône</div> <img alt='contenant un rouage' className={styles.numberedListImage} src={require("../../../../Assets/Images/settingsIcon.png")} /> <div>à droite du dossier de votre choix</div>
                            </div>
                        </NumberedList.Element>
                        <NumberedList.Element number={2}>
                            Cliquez sur “Dupliquer le dossier”  
                        </NumberedList.Element>
                        <NumberedList.Element number={3}>
                            Choisissez la date et le début du dossier  
                        </NumberedList.Element>
                        <NumberedList.Element number={4}>
                            Définissez les statuts d’actions à dupliquer et leur statut dans votre nouveau dossier  
                        </NumberedList.Element>
                    </NumberedList>
                </Article>
            </Subcategory>
        </Category>
    )
}