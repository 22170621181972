import addProfilePicture from "../../../../Assets/Videos/StartingTaskoya/Ajouter son avatar.webm";
import linkCalendar from "../../../../Assets/Videos/StartingTaskoya/Lier son agenda.webm";
import manageSettings from "../../../../Assets/Videos/StartingTaskoya/Parametrer ses préférences.webm";
import addEmail from "../../../../Assets/Videos/StartingTaskoya/Renseigner son adresse e-mail.webm";
import Article from "../../../../Components/Article/Article";
import Category from "../../../../Components/Category/Category";

export default function AccountManagement() {
    return (
        <Category title={"Administration du compte "}>
            <Article anchor={"setEmail"} title={"Renseigner son mail"}>
                <i>Vous pourrez ainsi créer des actions directement depuis vos mails !</i>
                <div>
                    Dans les paramètres, rendez-vous dans modifier le profil, vous pourrez ajouter une adresse EMail à chacune de vos organisations.
                </div>
                <Article illustration={addEmail} />
                <div>
                    Lorsque vous recevez un mail que vous souhaitez transformer en action dans Taskoya. Envoyez un E-Mail à taskinmail@taskoya.com.
                    Une tâche sera automatiquement créée dans votre dossier "Boîte d’entrée”.{" "}
                </div>
            </Article>
            <Article anchor={"addProfilePicture"} title={"Ajouter son avatar"} illustration={addProfilePicture}>
                Pour une vision plus claire de l’organisation, ajoutez une photo à votre profil.
            </Article>
            <Article anchor={"editSettings"} title={"Paramétrer ses préférences"} illustration={manageSettings}>
                Paramétrez vos préférences pour gagner du temps lors de la création de vos actions !
            </Article>
            <Article anchor={"linkCalendar"} title={"Lier son agenda"} illustration={linkCalendar}>
                En ajoutant votre agenda, vous vous offrez une vue complète de votre semaine ! La vue agenda superpose les événements de votre agenda
                aux actions qui sont déjà dans Taskoya.
                <i>Attention, le lien de votre agenda à ajouter doit être en format ICS </i>
            </Article>
        </Category>
    );
}
