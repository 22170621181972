import { useRef } from "react";
import EmbeddedYoutubeVideo from "../../../Components/EmbeddedYoutubeVideo/EmbeddedYoutubeVideo";
import styles from "./UseCase1.module.css";

export default function UseCase1() {
    const ref = useRef();

    return (
        <>
            <div className={styles.content} ref={ref}>
                <h2 className={styles.title}>Simplifiez la gestion de votre service Administratif et Financier</h2>
                <p className={styles.paragraph}>
                    Taskoya est parfaitement adapté pour simplifier et optimiser la gestion d’un service Administratif et Financier.
                </p>
                <p className={styles.paragraph}>
                    Gestion comptable et financière, fiscalité, gestion des assurances, gestion des ressources humaines, management, autant de sujets
                    qui vous concernent forcément... Et pour les quels nous vous proposons des solutions concrètes et éprouvées!
                </p>
                <p className={styles.paragraph}>
                    <b>Pour cela, nous allons prendre le cas d’Audrey Naudé, Directrice administrative et financière </b>de la société Viwamétal.
                    Viwamétal est un sous-traitant en tôlerie basé à Ostwald près de Strasbourg. L’entreprise est composée de 60 collaborateurs.
                </p>
                <p className={styles.paragraph}>
                    Nous avons eu le plaisir d’organiser un webinaire avec Audrey. Elle y présente son poste, ses missions et coment Taskoya l’aide à
                    simplifier la gestion de son service et la réalisation de ses nombreuses missions.
                </p>
                <p className={styles.paragraph}>
                    <b>Elle nous présente également son Taskoya et comment elle y organise le service</b> Administratif et Financier de Viwamétal.
                    Vous trouverez ci-dessous le replay de ce webinaire.
                </p>
                <EmbeddedYoutubeVideo containerElementRef={ref} link={"https://www.youtube.com/embed/Uym_rsDVj3I"} />
                <p className={styles.paragraph}>
                    Suite à ce webinaire, Audrey a également accepté de répondre à quelques questions lors d’un témoignage réalisé au sein des locaux
                    de Viwamétal:
                </p>
                <EmbeddedYoutubeVideo containerElementRef={ref} link={"https://www.youtube.com/embed/NHKQxHts0s8"} />
            </div>
        </>
    );
}
