import styles from './Category.module.css';

export default function Category({title, children})
{
    return (
        <div id="top" className={styles.main}>
            <h2 className={styles.title}>{title.toUpperCase()}</h2>
            {children}
        </div>
    );
}