import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import search from "../../Assets/Images/search.svg";
import flatten from "../../Tools/flatten";
import styles from "./SearchBar.module.css";

const searchTags = {
    "Débuter sur Taskoya": "/startingTaskoya/firstSteps",
    "Créer un dossier": "/startingTaskoya/firstSteps#createFolder",
    "Où créer un dossier": "/startingTaskoya/firstSteps#whereToCreateFolder",
    "Ajouter des superviseurs au dossier": "/startingTaskoya/firstSteps#addSupervisorsToFolder",
    "Créer des tags": "/startingTaskoya/firstSteps#createTags",
    "Créer une action": "/startingTaskoya/firstSteps#createTask",
    "Où créer une action": "/startingTaskoya/firstSteps#whereToCreateTask",
    "Remplir la section action": "/startingTaskoya/firstSteps#fillTaskSection",
    "Paramétrer la section délégation d’une action collaborative": "/startingTaskoya/firstSteps#setupDelegationSection",
    "Répétez votre action régulièrement en planifiant une récurrence": "/startingTaskoya/firstSteps#setupRecurrence",
    "Être notifié à l’approche de l’échéance": "/startingTaskoya/firstSteps#notifyBeforeDeadline",
    "Gérer ses actions": "/startingTaskoya/baseFunctionalities#manageTasks",
    "Changer le statut de son action": "/startingTaskoya/baseFunctionalities#changeTaskStatus",
    "Repousser une action": "/startingTaskoya/baseFunctionalities#postponeTask",
    "Dupliquer une action": "/startingTaskoya/baseFunctionalities#duplicateTask",
    "Modifier l'exécutant d’une action": "/startingTaskoya/baseFunctionalities#editTaskUser",
    "Ajouter une pièce jointe": "/startingTaskoya/baseFunctionalities#addFile",
    "Maîtriser les filtres": "/startingTaskoya/baseFunctionalities#masterFilters",
    "Rechercher par nom d’action": "/startingTaskoya/baseFunctionalities#filterByName",
    "Rechercher par dossier": "/startingTaskoya/baseFunctionalities#filterByFolder",
    "Rechercher par tag": "/startingTaskoya/baseFunctionalities#filterByTag",
    "Rechercher par exécutant": "/startingTaskoya/baseFunctionalities#filterByUser",
    "Organiser l'affichage": "/startingTaskoya/baseFunctionalities#organizeDisplay",
    "Modifier l’affichage": "/startingTaskoya/baseFunctionalities#modifyDisplay",
    "Gérer les vues": "/startingTaskoya/baseFunctionalities#manageViews",
    "Gérer ses dossiers": "/startingTaskoya/baseFunctionalities#manageFolders",
    "Modifier un dossier": "/startingTaskoya/baseFunctionalities#editFolder",
    "Dupliquer un dossier": "/startingTaskoya/baseFunctionalities#duplicateFolder",
    "Renseigner son mail": "/startingTaskoya/accountManagement#setEmail",
    "Ajouter son avatar": "/startingTaskoya/accountManagement#addProfilePicture",
    "Paramétrer ses préférences": "/startingTaskoya/accountManagement#editSettings",
    "Lier son agenda": "/startingTaskoya/accountManagement#linkCalendar",
    "Créer un filtre personnalisé en fonction de ses besoins": "/advancedFeatures/miscellaneous#createFilter",
    "Horodater une note, dans une tâche": "/advancedFeatures/miscellaneous#timestampDescription",
    "Multipicking de tâches": "/advancedFeatures/miscellaneous#multipickTasks",
    "Création d’une tâche à partir d’un E-Mail reçu": "/advancedFeatures/miscellaneous#taskInMail",
    "Dupliquer une tâche à plusieurs exécutants": "/advancedFeatures/miscellaneous#duplicateToUsers",
    "Suivre une action": "/advancedFeatures/miscellaneous#followTask",
    "Créer un dossier modèle": "/advancedFeatures/miscellaneous#createTemplateFolder",
    "Administratif & Finance": "/useCases/1",
    "Comment la méthode Taskoya simplifie votre gestion administrative et financière ?": "/webinars/1",
};

export default function SearchBar() {
    const container = useRef(null);
    const navigate = useNavigate();
    const [searchText, setSearchText] = useState("");

    function onSearchTextChange(e) {
        setSearchText(e.target.value);
    }

    function renderMatchingTags(matchingUrls) {
        return matchingUrls.slice(0, 5).map((matchingUrl) => (
            <div key={matchingUrl.link} onClick={() => navigate(matchingUrl.link)} className={styles.searchResult}>
                {matchingUrl.name}
            </div>
        ));
    }

    function findMatchingTags() {
        if (!searchText.length) return [];
        let normalizedSearchText = flatten(searchText);
        let matchingTags = Object.keys(searchTags).filter((tag) => {
            return flatten(tag).includes(normalizedSearchText);
        });
        let matchingUrls = matchingTags.map((key) => {
            return { name: key, link: searchTags[key] };
        });
        return matchingUrls;
    }

    let matchingUrls = findMatchingTags();

    return (
        <div className={styles.container}>
            <div
                ref={(ref) => (container.current = ref)}
                className={styles.inputContainer}
                style={{ borderBottomLeftRadius: matchingUrls.length ? 0 : "2rem", borderBottomRightRadius: matchingUrls.length ? 0 : "2rem" }}
            >
                <img alt="Icône de loupe" className={styles.inputImage} src={search} />
                <input className={styles.input} value={searchText} onChange={onSearchTextChange} placeholder="Rechercher..." type="text" />
            </div>
            {searchText.length && matchingUrls.length ? <div className={styles.searchResults}>{renderMatchingTags(matchingUrls)}</div> : null}
        </div>
    );
}
