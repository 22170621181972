import EmbeddedYoutubeVideo from "../../../Components/EmbeddedYoutubeVideo/EmbeddedYoutubeVideo";
import styles from "./Webinar1.module.css";

export default function Webinar1({}) {
    return (
        <div className={styles.content}>
            <h2 className={styles.title}>Comment la méthode Taskoya simplifie votre gestion administrative et financière?</h2>
            <p className={styles.paragraph}>
                Nous avons eu le plaisir d’organiser un webinaire avec Audrey Naudé, Directrice Administrative et Financière de Viwamétal.
            </p>
            <p className={styles.paragraph}>
                Elle y présente son poste, ses missions et coment Taskoya l’aide à simplifier la gestion de son service et la réalisation de ses
                nombreuses missions.
            </p>
            <p className={styles.paragraph}>
                Elle nous présente également son Taskoya et comment elle y organise le service Administratif et Financier de Viwamétal. Vous trouverez
                ci-dessous le replay de ce webinaire.
            </p>
            <EmbeddedYoutubeVideo link={"https://www.youtube.com/embed/Uym_rsDVj3I"} />
        </div>
    );
}
