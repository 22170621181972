import styles from './Article.module.css';

export default function Article({title, illustration, anchor, children})
{
    return (
        <div id={anchor} className={styles.main}>
            {title && <h2 className={styles.title}>{title}</h2>}
            <div className={styles.main}>
                {children}
                {illustration && <video className={styles.video} autoPlay loop muted alt="Vidéo de démonstration" src={illustration}/>}
            </div>
        </div>
    );
}