import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import styles from "./StartingTaskoya.module.css";

export default function StartingTaskoya() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (location.hash) {
            setTimeout(() => {
                document.querySelector(location.hash)?.scrollIntoView({ behavior: "smooth" });
            }, 400);
        }
    }, [location.hash]);

    return (
        <div className={styles.main}>
            <div className={styles.header}>
                <img alt="Le logo de Taskoya" onClick={() => navigate("/")} src={require("../../../Assets/Images/taskoya256x150.png")} />
            </div>
            <h1 className={styles.title}>DÉBUTER SUR TASKOYA</h1>
            <div className={styles.body}>
                <div className={styles.navbar}>
                    <HashLink className={styles.categoryLink} to={"/startingTaskoya/firstSteps#top"}>
                        LES PREMIERS PAS
                    </HashLink>
                    {location.pathname.includes("/firstSteps") && (
                        <>
                            <HashLink className={styles.subCategoryLink} smooth to={"/startingTaskoya/firstSteps#createFolder"}>
                                Créer un dossier
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#whereToCreateFolder"}>
                                Où créer un dossier
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#addSupervisorsToFolder"}>
                                Ajouter des superviseurs au dossier
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#createTags"}>
                                Créer des tags
                            </HashLink>
                            <HashLink className={styles.subCategoryLink} smooth to={"/startingTaskoya/firstSteps#createTask"}>
                                Créer une action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#whereToCreateTask"}>
                                Où créer une action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#fillTaskSection"}>
                                Remplir la section action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#setupDelegationSection"}>
                                Paramétrer la section délégation d’une action collaborative
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#setupRecurrence"}>
                                Répétez votre action régulièrement en planifiant une récurrence
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/firstSteps#notifyBeforeDeadline"}>
                                Être notifié à l’approche de l’échéance
                            </HashLink>
                        </>
                    )}
                    <HashLink className={styles.categoryLink} to={"/startingTaskoya/baseFunctionalities#top"}>
                        LES FONCTIONNALITÉS DE BASE
                    </HashLink>
                    {location.pathname.includes("/baseFunctionalities") && (
                        <>
                            <HashLink className={styles.subCategoryLink} smooth to={"/startingTaskoya/baseFunctionalities#manageTasks"}>
                                Gérer ses actions
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#changeTaskStatus"}>
                                Changer le statut de son action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#postponeTask"}>
                                Repousser une action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#duplicateTask"}>
                                Dupliquer une action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#editTaskUser"}>
                                Modifier l'exécutant d’une action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#addFile"}>
                                Ajouter une pièce jointe
                            </HashLink>
                            <HashLink className={styles.subCategoryLink} smooth to={"/startingTaskoya/baseFunctionalities#masterFilters"}>
                                Maîtriser les filtres
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#filterByName"}>
                                Rechercher par nom d’action
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#filterByFolder"}>
                                Rechercher par dossier
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#filterByTag"}>
                                Rechercher par tag
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#filterByUser"}>
                                Rechercher par exécutant
                            </HashLink>
                            <HashLink className={styles.subCategoryLink} smooth to={"/startingTaskoya/baseFunctionalities#organizeDisplay"}>
                                Organiser l'affichage
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#modifyDisplay"}>
                                Modifier l’affichage
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#manageViews"}>
                                Gérer les vues
                            </HashLink>
                            <HashLink className={styles.subCategoryLink} smooth to={"/startingTaskoya/baseFunctionalities#manageFolders"}>
                                Gérer ses dossiers
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#editFolder"}>
                                Modifier un dossier
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/baseFunctionalities#duplicateFolder"}>
                                Dupliquer un dossier
                            </HashLink>
                        </>
                    )}
                    <HashLink className={styles.categoryLink} to={"/startingTaskoya/accountManagement#top"}>
                        ADMINISTRATION DU COMPTE
                    </HashLink>
                    {location.pathname.includes("/accountManagement") && (
                        <>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/accountManagement#setEmail"}>
                                Renseigner son mail
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/accountManagement#addProfilePicture"}>
                                Ajouter son avatar
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/accountManagement#editSettings"}>
                                Paramétrer ses préférences
                            </HashLink>
                            <HashLink className={styles.articleLink} smooth to={"/startingTaskoya/accountManagement#linkCalendar"}>
                                Lier son agenda
                            </HashLink>
                        </>
                    )}
                </div>
                <div className={styles.content}>
                    <Outlet />
                </div>
                <div className={styles.rightBar}></div>
            </div>
        </div>
    );
}
