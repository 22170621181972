import styles from "./UseCaseItem.module.css";

export default function UseCaseItem({ title, category, description, backgroundImage, companyName, companyLogo, onClick }) {
    return (
        <div className={styles.container}>
            <div className={styles.imageContainer}>
                <h3
                    className={styles.title}
                    style={{
                        display: title ? "flex" : "none",
                        position: backgroundImage ? "absolute" : "relative",
                        background: backgroundImage ? "" : "initial",
                    }}
                >
                    {title}
                </h3>
                {backgroundImage && <img alt="Illustration du cas d'usage" className={styles.image} src={backgroundImage} />}
            </div>
            <div className={styles.content}>
                <div className={styles.companyHeader}>
                    <div style={{ flex: 1 }}>
                        <h3 className={styles.companyName}>{companyName}</h3>
                        <div className={styles.category}>{category}</div>
                    </div>
                    {companyLogo && <img style={{ height: "5rem" }} alt={`Logo de l'entreprise ${companyName}`} src={companyLogo} />}
                </div>
                <div className={styles.description}>{description}</div>
                <button onClick={onClick} className={styles.button}>
                    Consulter
                </button>
            </div>
        </div>
    );
}
