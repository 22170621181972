import styles from './NumberedList.module.css';


export default function NumberedList({children})
{
    return children;
}

NumberedList.Element = function ({number, children}) {
    return (
        <div className={styles.list}>
            <div className={styles.number}>{number}</div>
            <div className={styles.children}>{children}</div>
        </div>
    );
}