import styles from './Subcategory.module.css';

export default function Subcategory({title, children, anchor})
{
    return (
        <div id={anchor} className={styles.main}>
            <h2 className={styles.title}>{title}</h2>
            <div className={styles.main}>
                {children}
            </div>
        </div>
    );
}