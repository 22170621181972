import useCaseIcon from "../../Assets/Images/casUsage.svg";
import MainNavigationButton from "../../Components/MainNavigationButton/MainNavigationButton";
import SearchBar from "../../Components/SearchBar/SearchBar";
import styles from "./Home.module.css";

export default function Home() {
    return (
        <>
            <header className={styles.header}>
                <div className={styles.headerLeftContainer}>
                    <img className={styles.leftImage} alt="" src={require("../../Assets/Images/headerLeftImage.png")} />
                </div>
                <div className={styles.headerCenter}>
                    <div>
                        <img alt="Le logo de Taskoya" src={require("../../Assets/Images/taskoya256x150.png")} className={styles.taskoyaLogo} />
                    </div>
                    <div>
                        <h1 className={styles.title}>Centre d'aide</h1>
                        <SearchBar />
                    </div>
                </div>
                <div className={styles.headerRightContainer}>
                    <img className={styles.leftImage} alt="" src={require("../../Assets/Images/headerRightImage.png")} />
                </div>
            </header>
            <main className={styles.mainContent}>
                <MainNavigationButton
                    link={"/startingTaskoya/firstSteps#test"}
                    title={"Débuter sur Taskoya"}
                    description={"Vos premiers pas sur Taskoya"}
                    icon={require("../../Assets/Images/debuter.png")}
                />
                <MainNavigationButton
                    title={"Fonctionnalités avancées"}
                    description={"Passer à la vitesse supérieure"}
                    icon={require("../../Assets/Images/avancees.png")}
                    link={"/advancedFeatures/miscellaneous"}
                />
                <MainNavigationButton
                    link={"/useCases"}
                    title={"Cas d'usage"}
                    description={"Les Taskoyeurs modèles nous montrent l'exemple"}
                    icon={useCaseIcon}
                />
                <MainNavigationButton
                    title={"Nouveautés"}
                    description={"Les dernières fonctionnalités à votre disposition"}
                    icon={require("../../Assets/Images/nouveautes.png")}
                    link={"/news"}
                />
                <MainNavigationButton
                    title={"Envoyez nous un message"}
                    description={"Venez nous poser directement votre question"}
                    icon={require("../../Assets/Images/chat.png")}
                    external
                    link={"https://www.taskoya.com/contact"}
                />
                <MainNavigationButton
                    link={"/faq"}
                    title={"FAQ"}
                    description={"Une ribambelle de réponses juste pour vous"}
                    icon={require("../../Assets/Images/FAQ.png")}
                />
            </main>
        </>
    );
}
