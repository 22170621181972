import { useNavigate } from "react-router-dom";
import UseCaseItem from "../../../../Components/UseCaseItem/UseCaseItem";
import styles from "./UseCasesHome.module.css";

export default function UseCasesHome({}) {
    const navigate = useNavigate();
    return (
        <div className={styles.body}>
            <h2 className={styles.subtitle}>Cas d'usage</h2>
            <section className={styles.section}>
                <UseCaseItem
                    title={"Administratif & Finance"}
                    backgroundImage={null}
                    companyName={""}
                    companyLogo={null}
                    category={"Service administratif et financier"}
                    description={
                        "Découvrez, via le retour d’expérience d’une DAF, utilisatrice Taskoya, comment vous pouvez simplifier la gestion de votre service !"
                    }
                    onClick={() => navigate("/useCases/1")}
                />
            </section>
            <h2 className={styles.subtitle}>Webinaires</h2>

            <section className={styles.section}>
                <UseCaseItem
                    title={"Comment la méthode Taskoya simplifie votre gestion administrative et financière ?"}
                    backgroundImage={require("../../../../Assets/Images/UseCases/webinaire1.jpg")}
                    companyName={""}
                    companyLogo={null}
                    category={"Service administratif et financier"}
                    description={
                        "Audrey Naudé, DAF chez Viwamétal présente comment elle a simplifié sa gestion administrative et financière grâce à la méthode Taskoya."
                    }
                    onClick={() => navigate("/webinars/1")}
                />
                <UseCaseItem
                    title={"Dopez l'efficacité de vos équipes grâce à un système d'organisation efficace"}
                    backgroundImage={require("../../../../Assets/Images/UseCases/webinaire2.jpg")}
                    companyName={""}
                    companyLogo={null}
                    category={"Manager/Chef de service"}
                    description={"Sortez de vos multiples tableaux excel et feuilles volantes en adoptant la méthode d’organisation Taskoya."}
                    onClick={() => (window.location.href = "https://www.youtube.com/watch?v=CTzkZ3KV77k")}
                />
            </section>
            <h2 className={styles.subtitle}>Témoignages</h2>

            <section className={styles.section}>
                <UseCaseItem
                    title={""}
                    backgroundImage={require("../../../../Assets/Images/UseCases/ak.jpg")}
                    companyName={"AK Industries"}
                    companyLogo={require("../../../../Assets/Images/UseCases/ak_logo.jpg")}
                    category={"Industrie"}
                    description={
                        "« Nous avons testé beaucoup de logiciels mais la plupart sont trop lourds et demandent beaucoup de temps d’apprentissage. »"
                    }
                    onClick={() => navigate("/testimonials/1")}
                />
                <UseCaseItem
                    title={""}
                    backgroundImage={require("../../../../Assets/Images/UseCases/defymed.jpg")}
                    companyName={"Defymed"}
                    companyLogo={require("../../../../Assets/Images/UseCases/defymed_logo.jpg")}
                    category={"Médical"}
                    description={
                        "« Nous avions des gros problèmes dans le suivi, dans l’accomplissement et l’attribution des tâches avec différentes personnes »"
                    }
                    link={() => (window.location.href = "https://www.youtube.com/watch?v=6XN9TcZA3rE")}
                />
                <UseCaseItem
                    title={""}
                    backgroundImage={require("../../../../Assets/Images/UseCases/viwametal.jpg")}
                    companyName={"Viwamétal"}
                    companyLogo={require("../../../../Assets/Images/UseCases/viwametal_logo.png")}
                    category={"Industrie"}
                    description={"« Même si j'étais amenée à quitter Viwamétal, je ferai en sorte de conserver mon compte Taskoya pour mon travail »"}
                    link={() => (window.location.href = "https://www.youtube.com/watch?v=fynmFFqwJr0")}
                />
            </section>
        </div>
    );
}
